import * as React from "react";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { resizeImage } from "../../hooks/useImage";

interface CameraProps { }

export const Camera = (props: CameraProps) => {

  const onChangeCamera = (e: any) => {
    var file = e.target.files[0];
    var reader = new FileReader();
    reader.onloadend = function () {
      let input: any = window?.document?.getElementById("imgCustomField");
      let div: any = window?.document?.getElementById("img-show-container")
      let img: any = window?.document?.getElementById("showImg");
      if (!!input && !!div && !!img) {
        resizeImage(reader.result, 180, 130).then((result) => {
          img.src = result;
          input.value = result;
          img.className = "show img-responsive img-upload";
          div.className = "show img-show-container";
        });

      }
    };
    reader.readAsDataURL(file);

  };


  return (
    <div>
      <label htmlFor="avatar">
        <AddAPhotoIcon sx={{ color: "#000000" }} />
      </label>
      <input
        type="file"
        accept="image/*"
        name="avatar"
        id="avatar"
        className="hidden"
        onChange={onChangeCamera}
      />
    </div>
  );
};
