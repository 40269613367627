import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { AddWarranty } from "../icons/AddWarranty";
import { Bottom } from "../App/Bottom";
import { ClientList } from "../App/ClientList";
import { NewWarranty } from "./NewWarranty";
import { NewBattery } from "./NewBattery";
import { Camera } from "../App/Camera";
import { ClientCard } from "../App/ClientCard";
import AppConfig from "../../constants/AppConfig";
import { Dialog } from "../App/Dialog";
import Typography from "@mui/material/Typography";
import { StoreContext } from "../../store/StoreProvider";
import { types } from "../../store/Warranty";
import { types as typesDialog } from "../../store/Dialog";
import {
  clientAddress, CombustionV, getWarranty,
  GetWarrantyRequest, MarModeloV, TipoUsoV,
  WarrantyCardCardCode, WarrantyRequestParams,
  getWarrantyById, CorpWarrantyCardCardCode,
  CorpMarcaModeloVehiculos, CorpWarrantyCardById,
  CorpClientAddress, getCorpFrecuenciaGarantia,
  CorpGetReporteActivaciones, GetReporteActivaciones
} from "../../store/sagas/Warranty";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Loading from "../Loading";
import { useDebounce } from "../../hooks/useDebounce";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import moment from "moment";
import { WarrantyActivationListItem } from "./WarrantyActivationListItem";
import { BatteryListModal } from "./BatteryListModal";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import { IconSearch } from "../icons/IconSearch";
import { Button } from "@material-ui/core";
import { Alert } from "../Alert";
// import Box from "@mui/material/Box";
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { getI18N } from '../../i18n'
// import { background } from "../../assets/Media";

const { validTypes } = AppConfig;

interface WarrantyActivationsListProps {
  typeTab?: string;
}

var prevCli = "";
var prevTab = "";

const ACTIVE_DIALOG = "new_code";

export const WarrantyActivationsList = (
  props: WarrantyActivationsListProps
) => {
  const [store, dispatch] = React.useContext(StoreContext);
  const [loading, setLoad] = React.useState(false);
  const [plateCode, setPlate] = React.useState(store?.searchPlateCode ?? "");
  const [documentCode, setDoc] = React.useState(store?.searchDocumentCode ?? "");
  const [loadingPlate, setLoadPlate] = React.useState(false);
  const [loadingDoc, setLoadDoc] = React.useState(false);
  // const [downloadReport, setDownloadReport] = React.useState(false);

  const [origen, setOrigen] = React.useState(
    store?.user?.Country
  );

  const [fetched, setFetch] = React.useState(false);
  const [currentClient, setClient] = React.useState(prevCli);
  const [warrantyData, setWarrantyData] = React.useState([]);

  const [batteryList, setBatteryList] = React.useState([]);
  const [showBatteryList, setShowBatteryList] = React.useState(false);

  const AlertRef = React.useRef<any>();
  const BatteryRef = React.useRef<any>();

  const [valueFor, setValueFor] = React.useState<Date | null>(store?.initDate);
  const [valueTo, setValueTo] = React.useState<Date | null>(store?.endDate);

  const { Country } = store?.user;
  const currentLocale: string = Country || "PE";
  const i18n = getI18N({ currentLocale })

  // const [currentTypeTab, setCurrentTypeTab] = React.useState(prevTab ?? "");

  const setEndLoadDebounce = useDebounce(() => {
    setLoad(false);
  }, 500);


  React.useEffect(() => {
    if (currentClient && currentClient !== store?.selected_client.Id) {
      // setCurrentTypeTab(props?.typeTab ?? "");
      requestDate();
    }
  }, [store?.selected_client, currentClient]);


  // const loadCorpMarcaModeloVehiculos = () => {
  //   new Promise(function (resolve, reject) {
  //     CorpMarcaModeloVehiculos().then((data) => {
  //       const arMarcas = data?.value?.Marca;
  //       const arModelos = data?.value?.Modelo;
  //       let objSend = {
  //         MarModeloV: arMarcas,
  //         ModeloV: arModelos,
  //       }
  //       resolve(dispatch({ type: types.setSelectOptionsData, payload: objSend }));
  //     })
  //   })

  // }

  const open = () => {
    if (!store.dialog) {
      dispatch({ type: typesDialog.open, payload: ACTIVE_DIALOG });
    } else {
      dispatch({ type: typesDialog.close });
    }
  };

  const search = (f: any) => {
    if (!!store?.searchWarranty) {
      let data: any = f.CardName.toLocaleUpperCase().includes(
        store?.searchWarranty.toLocaleUpperCase()
      );
      if (!data || !data.length) {
        data = f.IdMiniCod.toLocaleUpperCase().includes(
          store?.searchWarranty.toLocaleUpperCase()
        );
        if (!!data) return data;
      }
      if (!data || !data.length) {
        data = f.DocEntry.toString().includes(
          store?.searchWarranty.toLocaleUpperCase()
        );
        if (!!data) return data;
      }
      if (!data || !data.length) {
        data = f.DocumentoIdentidadCliente.toLocaleUpperCase().includes(
          store?.searchWarranty.toLocaleUpperCase()
        );
        if (!!data) return data;
      }
      if (!data || !data.length) {
        data = f.CardCode.toLocaleUpperCase().includes(
          store?.searchWarranty.toLocaleUpperCase()
        );
        if (!!data) return data;
      }
      if (!data || !data.length) {
        data = f.Modelo.toLocaleUpperCase().includes(
          store?.searchWarranty.toLocaleUpperCase()
        );
        if (!!data) return data;
      }
      if (!data || !data.length) {
        data = f.UserReg.toLocaleUpperCase().includes(
          store?.searchWarranty.toLocaleUpperCase()
        );
        if (!!data) return data;
      }
      if (!data || !data.length) {
        data = f.UserReg.toLocaleUpperCase().includes(
          store?.searchWarranty.toLocaleUpperCase()
        );
        if (!!data) return data;
      }

      return data;
    } else {
      return true;
    }
  };

  const findById = (res: any = false, type: string = "", page: number = 0) => {
    let obj = {
      placa: !!res ? "" : plateCode,
      ruc: !!res ? "" : documentCode,
      CardCode: !!Object.keys(store.selected_client).length ? store.selected_client.Id : "",
      origenBat: origen,
      tipoConsulta: 0,
    };

    if (loadingPlate === true || loadingDoc === true) {
      AlertRef?.current?.open("Se esta ejecutando una busqueda", "error");
      return;
    }

    // setLoad(true);

    if (!!type) {

      if (type === "plate") {
        setLoadPlate(true);
        obj.ruc = "";
      }
      if (type === "document") {
        setLoadDoc(true);
        obj.placa = "";
      }
    } else {
      // setLoad(true);
    }

    if (store?.userAppCorp) {

      if (type === "plate" && !obj?.placa) {
        AlertRef?.current?.open("Ingrese una placa", "error");
        setLoadPlate(false);
        return;
      }

      if (type === "document" && !obj?.ruc) {
        AlertRef?.current?.open("Ingrese una Unidad", "error");
        setLoadDoc(false);
        return;
      }

      // loadCorpMarcaModeloVehiculos();

      let objCorp = {
        FechaInicio: "",
        FechaFin: "",
        // FechaInicio: moment(valueFor).format("yyyy-MM-DD"),
        // FechaFin: moment(valueTo).format("yyyy-MM-DD"),
        codCliente: store?.selected_client.Id,
        pag: page,
        maxRows: 50,
        Placa: obj?.placa,
        Unidad: obj?.ruc
      };

      if (props?.typeTab === "0") {
        objCorp.FechaInicio = moment(valueFor).format("yyyy-MM-DD");
        objCorp.FechaFin = moment(valueTo).format("yyyy-MM-DD");
      }

      CorpWarrantyCardCardCode(objCorp).then((response) => {
        setEndLoadDebounce()
        setFetch(true);
        prevCli = store?.selected_client.Id;
        setClient(prevCli);

        if (response.statusCode === 200) {

          const dataWarrantyResponse = response?.value;

          const countList: number = (response.size);
          const sizePage: number = (objCorp.maxRows);

          let lastPage: number = Math.floor(countList / sizePage);

          if (countList % sizePage !== 0) {
            lastPage++;
          }

          const pagination = {
            page: (objCorp.pag + 1),
            pageCount: lastPage
          }

          if (response.size <= 0) {
            setLoadPlate(false);
            setLoadDoc(false);
            AlertRef?.current?.open(response?.message ?? "Codigo no encontrado", "error");

            setTimeout(() => {
              if (type === "plate") {
                dispatch({ type: types.setWarrantyActivationPlateList, payload: dataWarrantyResponse, searchPlateCode: objCorp?.Placa, pagination: pagination });
              }

              if (type === "document") {
                dispatch({ type: types.setWarrantyActivationDocumentList, payload: dataWarrantyResponse, searchDocumentCode: objCorp?.Unidad, pagination: pagination });
              }
            }, 500);

            return;
          }

          if (type === "plate") {
            dispatch({ type: types.setWarrantyActivationPlateList, payload: dataWarrantyResponse, searchPlateCode: objCorp?.Placa, download: true, pagination: pagination });
          }

          if (type === "document") {
            dispatch({ type: types.setWarrantyActivationDocumentList, payload: dataWarrantyResponse, searchDocumentCode: objCorp?.Unidad, download: true, pagination: pagination });
          }
        }

      }).catch((error) => {
        // setLoad(false);
        setLoadPlate(false);
        setLoadDoc(false);
        AlertRef?.current?.open("Codigo no encontrado", "error");
      });

      return;
    }

    getWarrantyById(obj)
      .then((data) => {
        if (!!data.length) {
          if (!!data[0]?.DscResponse && !!data[0]?.CodResponse) {
            let msg: string = "Error";
            if (!!data[0]?.DscResponse) {
              msg = data[0]?.DscResponse;
            }
            AlertRef?.current?.open(msg, "error");
            // setLoad(false);
            return;
          }

          if (data.length >= 1) {
            setBattery(data[0], type);
          }
        } else {
          // setLoad(false);
          setLoadPlate(false);
          setLoadDoc(false);
          AlertRef?.current?.open(
            "El codigo unico ingresado no pertenece al SN seleccionado",
            "error"
          );
        }
      })
      .catch((error) => {
        // setLoad(false);
        setLoadPlate(false);
        setLoadDoc(false);
        AlertRef?.current?.open("Codigo no encontrado", "error");
      });
  };

  const setBattery = (obj: any, type: string) => {
    let objReq = {
      idTarjeta: obj?.IdTarjetaGarantia,
      qrCode: obj?.CodBateria,
      placa: "",
      ruc: "",
      cardCode: obj?.Documento,
      origenBat: store?.user?.Country,
    };
    getWarranty(objReq).then((dataWarranty: any) => {
      if (type === "plate") {
        dispatch({ type: types.setWarrantyActivationPlateList, payload: dataWarranty });
      }

      if (type === "document") {
        dispatch({ type: types.setWarrantyActivationDocumentList, payload: dataWarranty });
      }

    });
  };

  const arTabsName = [
    "activationList",
    "plateList",
    "documentList"
  ];

  // const current
  const pageCountTab = store?.pagination?.[arTabsName[store?.active_warranty_tab]]?.pageCount;
  const currentpageTab = store?.pagination?.[arTabsName[store?.active_warranty_tab]]?.page;

  const [currentPage, setCurrentPage] = React.useState<number>(currentpageTab || 0);
  const pageCount = pageCountTab || 0;

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);

    return requestDate("", (value - 1), true);

    /* if (store?.active_warranty_tab === 0) {
      return requestDate("", (value - 1), true);
    }

    if ([1, 2].includes(store?.active_warranty_tab)) {
      return findById(false, ((store?.active_warranty_tab === 1) ? "plate" : "document"), (value - 1));
    } */
  };

  const requestDate = (position: string = "", page: number = 0, paginating: boolean = false) => {

    if (paginating === false) setLoad(true);

    if (loadingPlate === true || loadingDoc === true) {
      AlertRef?.current?.open("Se esta ejecutando una busqueda", "error");
      return;
    }

    interface ObjTypeRequest {
      FechaIni: string;
      FechaFin: string;
      cardCode: string;
      Placa: string;
      NroDoc: string;
      flgtodo?: string | number;
    }

    const obj: ObjTypeRequest = {
      FechaIni: moment(valueFor).format("MM/DD/yyyy"),
      FechaFin: moment(valueTo).format("MM/DD/yyyy"),
      cardCode: store?.selected_client.Id,
      Placa: plateCode,
      NroDoc: documentCode,
      flgtodo: ''
    };

    // setLoad(true);

    if( [1, 2].includes(store?.active_warranty_tab)) {
      obj.FechaFin = '';
      obj.FechaIni = '';
      obj.flgtodo = 0;

      if (store?.active_warranty_tab === 1) {
        setLoadPlate(true);
        obj.NroDoc = "";
      } else if (store?.active_warranty_tab === 2) {
        setLoadDoc(true);
        obj.Placa = "";
      }
    }

    if (!!position) {
      let selectedDate = position === "start" ? moment(valueFor).format() : moment(valueTo).format()
      let obj = {
        selectedDate
      };

      if (position === "start") {
        dispatch({ type: types.setWarrantyActivationLisDateStart, payload: obj });
      } else {
        dispatch({ type: types.setWarrantyActivationLisDateEnd, payload: obj });
      }

      return;
    }

    if (store?.userAppCorp) {

      // loadCorpMarcaModeloVehiculos();

      let obj = {
        FechaInicio: moment(valueFor).format("yyyy-MM-DD"),
        FechaFin: moment(valueTo).format("yyyy-MM-DD"),
        codCliente: store?.selected_client.Id,
        pag: page,
        maxRows: 50,
        Placa: "",
        Unidad: ""
      };

      CorpWarrantyCardCardCode(obj).then((response) => {
        setEndLoadDebounce()
        setFetch(true);
        prevCli = store?.selected_client.Id;
        setClient(prevCli);
        if (response.statusCode === 200) {

          const countList: number = (response.size);
          const sizePage: number = (obj.maxRows);

          let lastPage: number = Math.floor(countList / sizePage);

          if (countList % sizePage !== 0) {
            lastPage++;
          }

          const pagination = {
            page: (obj.pag + 1),
            pageCount: lastPage
          }

          dispatch({ type: types.setWarrantyActivationList, payload: response.value, download: (response?.size > 0), pagination: pagination })
        }

      });

      return;
    }

    WarrantyCardCardCode(obj).then((res) => {
      setEndLoadDebounce()
      setFetch(true);
      prevCli = store?.selected_client.Id;
      setClient(prevCli);

      if (store?.active_warranty_tab === 0) {
        dispatch({ type: types.setWarrantyActivationList, payload: res, download: (res?.length > 0) })
      } else if (store?.active_warranty_tab === 1) {
        dispatch({ type: types.setWarrantyActivationPlateList, payload: res });
      } else if (store?.active_warranty_tab === 2) {
        dispatch({ type: types.setWarrantyActivationDocumentList, payload: res });
      }

    });
  }

  const setDetailParent = (obj: any) => {
    // console.log(obj)
    if (store?.userAppCorp) {
      let objReq = {
        idTarjetaGarantia: obj?.IdTarjeta
      };

      return new Promise((resolve, reject) => {
        CorpWarrantyCardById(objReq).then((data) => {

          if (data.statusCode !== 200) {
            AlertRef?.current?.open(data?.message ?? "Error al obtener la informacion", "error");
          }

          if (data.statusCode === 200) {
            const dataWarrantyCorp = data.value;

            new Promise(function (resolve, reject) {
              CorpMarcaModeloVehiculos().then((data) => {
                const arMarcas = data?.value?.Marca;
                const arModelos = data?.value?.Modelo;

                const paramsRequest = {
                  CodCliente: store?.selected_client?.Id
                }

                CorpClientAddress(paramsRequest).then((data) => {
                  const clientAddress = data.value;
                  getCorpFrecuenciaGarantia().then((data) => {
                    let objSend = {
                      clientAddress: clientAddress,
                      dataWarranty: dataWarrantyCorp,
                      selectData: {
                        TiempoGarantia: data?.value?.TiempoGarantia,
                        MarModeloV: arMarcas,
                        ModeloV: arModelos,
                        PeriodoRevision: data?.value?.PeriodoRevision
                      },
                      searchCodes: '',
                    };

                    resolve(dispatch({ type: types.setCorpBatteryList, payload: objSend }));
                  })
                })

              })
            }).then(() => {
              setTimeout(() => {
                dispatch({
                  type: typesDialog.open,
                  payload: ACTIVE_DIALOG,
                });
              }, 500);
            })
          }
        });
      })
    }

    return new Promise((resolve, reject) => {
      let objReq = {
        idTarjeta: obj?.IdTarjetaGarantia,
        qrCode: obj?.IdMiniCod,
        placa: "",
        ruc: "",
        cardCode: obj?.CardCode,
        origenBat: obj?.OrigenBat,
      };

      getWarranty(objReq).then((dataWarranty) => {
        new Promise(function (resolve, reject) {
          CombustionV({ country: store?.user?.Country }).then((dataComb) => {
            MarModeloV().then((data) => {
              let brands: any = [];
              let models: any = [];
              let dataMarMod = data.filter((d: any) => {
                let res;
                if (brands.indexOf(d.DESMARCA) === -1) {
                  brands.push(d.DESMARCA);
                  res = {
                    CODMARCA: d.CODMARCA,
                    DESMARCA: d.DESMARCA,
                  };
                }
                return res;
              });

              let dataMod = data.filter((d: any) => {
                let res;
                if (models.indexOf(d.DESMODELO) === -1) {
                  models.push(d.DESMODELO);
                  res = {
                    CODMODELO: d.CODMODELO,
                    DESMODELO: d.DESMODELO,
                  };
                }
                return res;
              });

              TipoUsoV({ country: store?.user?.Country }).then((dataTipo) => {
                clientAddress({
                  key: store?.selected_client?.Id,
                }).then((address) => {
                  let obj = {
                    clientAddress: address,
                    selectData: {
                      CombustionV: dataComb,
                      MarModeloV: dataMarMod,
                      ModeloV: dataMod,
                      TipoUsoV: dataTipo,
                    },
                    dataWarranty: dataWarranty[0],
                    searchCode: '',
                  };
                  resolve(dispatch({ type: types.setBattery, payload: obj }));
                });
              });
            });
          });
        }).then(() => {
          setLoad(false);
          dispatch({
            type: typesDialog.open,
            payload: ACTIVE_DIALOG,
          });
        });
      });

    })
  };

  const toggleClient = () => {
    if (!store?.openView || store?.openView === validTypes.battery) {
      BatteryRef?.current?.saveBattery().then((battery: any) => {
        dispatch({ type: types.setViewClient, payload: battery });
      });
    } else {
      dispatch({ type: types.setViewDefault });
    }
  };

  const SwitchView = () => {
    switch (store?.openView) {
      case validTypes.client:
        return <ClientList callback={toggleClient} />;
        break;
      case validTypes.warranty:
        return <NewWarranty callback={() => console.log()} />;
        break;
      case validTypes.warrantyDetail:
        return <NewWarranty callback={() => console.log()} />;
        break;
      case validTypes.battery:
        return <NewBattery ref={BatteryRef} callback={open} />;
        break;

      default:
        return <NewBattery ref={BatteryRef} callback={open} />;
        break;
    }
  };

  const showWarranty = () => {
    if (store?.battery?.Id === -1) {
      AlertRef?.current?.open("Debe registrar primero la bateria", "error");
    } else {
      let obj = {
        idTarjeta: store?.battery?.Id,
        idMiniCod: store?.battery?.Id,
        cardCode: store?.battery?.CardCode,
      };

      WarrantyRequestParams({ OrigenBat: store?.user?.Country }).then((warrantyFront) => {
        GetWarrantyRequest(obj).then((warrantyData) => {
          if (!!warrantyData.length) {
            dispatch({
              type: types.setWarrantyDetail,
              payload: warrantyData[0],
            });
          } else {
            dispatch({ type: types.setViewWarranty, payload: warrantyFront });
          }
        });
      });
    }
  };

  const RenderIcon = () => {
    switch (store?.openView) {
      case validTypes.battery:
        return (
          <IconButton
            style={{ color: "#000" }}
            sx={{ p: "10px" }}
            aria-label="Nueva garantia"
            onClick={showWarranty}
          >
            <AddWarranty />
          </IconButton>
        );
        break;
      case validTypes.warranty:
        return <Camera />;
        break;
      case validTypes.warrantyDetail:
        return <></>;
        break;

      default:
        return <></>
        // return (
        //   <IconButton
        //     style={{ color: "#000" }}
        //     sx={{ p: "10px" }}
        //     aria-label="Nueva garantia"
        //     onClick={showWarranty}
        //   >
        //     <AddWarranty />
        //   </IconButton>
        // );
        break;
    }
  };

  const RenderTitle = () => {
    let title: any;

    switch (store?.openView) {
      case validTypes.battery:
        title = store?.battery?.CardName || (
          <ClientCard callback={toggleClient} />
        );
        break;
      case validTypes.warranty:
        title = "Solicitud de garantia";
        break;
      case validTypes.warrantyDetail:
        title = "Detalle de solicitud de garantia";
        break;

      default:
        title = store?.battery?.CardName || (
          <ClientCard callback={toggleClient} />
        );
        break;
    }

    if (typeof title !== "string") {
      return <>{title}</>;
    } else {
      return (
        <Typography
          className="cardName-title"
          sx={{ ml: 2, flex: 1 }}
          variant="h6"
          component="div"
          align="center"
          style={{ color: "#000" }}
        >
          {title}
        </Typography>
      );
    }
  };

  const downloadReport = (titleType: string, response: any) => {
    const titleExcel = "Activaciones_" + titleType + '_' + (new Date()).getTime();
    const base64Data = store?.userAppCorp ? response?.value : response?.valueEX;

    const binaryData = atob(base64Data);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const view = new Uint8Array(arrayBuffer);

    for (let i = 0; i < binaryData.length; i++) {
      view[i] = binaryData.charCodeAt(i);
    }

    const blob = new Blob([arrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');

    a.style.setProperty('display', 'none');
    a.href = url;
    a.download = titleExcel + '.xlsx';
    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  const handleDownloadReport = () => {

    let obj = {
      CardCode: store?.selected_client?.Id,
      FechaInicio: "",
      FechaFin: "",
      Placa: "",
      Unidad: ""
      // FechaInicio: moment(valueFor).format("yyyy-MM-DD"),
      // FechaFin: moment(valueTo).format("yyyy-MM-DD"),
    };

    if (props?.typeTab === "0") {
      obj.FechaInicio = moment(valueFor).format("yyyy-MM-DD");
      obj.FechaFin = moment(valueTo).format("yyyy-MM-DD");
    }

    if (props?.typeTab === "1") {
      obj.Placa = plateCode;
    }

    if (props?.typeTab === "2") {
      obj.Unidad = documentCode;
    }

    AlertRef?.current?.open("Generando reporte", "success");

    if (store?.userAppCorp) {
      CorpGetReporteActivaciones(obj).then((response) => {
        if (response.statusCode !== 200) {
          return AlertRef?.current?.open(response?.message ?? "Error al generar el reporte", "error");
        }

        if (response.statusCode === 200) {

          let titleType = obj?.FechaInicio + '_' + obj?.FechaFin;

          if (props?.typeTab === "1") {
            titleType = "Placa";
          }

          if (props?.typeTab === "2") {
            titleType = "Unidad";
          }

          downloadReport(titleType, response);

        }
      })
      return;
    }

    GetReporteActivaciones(obj).then((response) => {
      if (response.statusCode !== 200) {
        return AlertRef?.current?.open(response?.message ?? "Error al generar el reporte", "error");
      }

      if (response.statusCode === 200) {

        let titleType = obj?.FechaInicio + '_' + obj?.FechaFin;

        if (props?.typeTab === "1") {
          titleType = "Placa";
        }

        if (props?.typeTab === "2") {
          titleType = "Unidad";
        }

        downloadReport(titleType, response);

      }
    })

  }

  let placeHolderSearch = "";

  if (store?.userAppCorp) {
    placeHolderSearch = "UNIDAD";
  } else {
    switch (store?.user?.Country) {
      case "PE":
      case "NAC":
        placeHolderSearch = "DNI / RUC";
        break;
      case "VE":
        placeHolderSearch = "CI / RIF";
        break;
      case "CL":
        placeHolderSearch = "RUT";
        break;
      case "EC":
        placeHolderSearch = "CI / RUC / CE";
        break;
      case "DO":
        placeHolderSearch = "CI / RNC";
        break;
      case "BO":
        placeHolderSearch = "CI / NIT";
        break;
      case "TT":
        placeHolderSearch = "NIC / TIN";
        break;
    }
  }

  const searchTab = (type: string) => {
    findById(false, type);
  };

  return (
    <div className="warranty-list-container">
      <Alert ref={AlertRef} />
      {props?.typeTab === "0" && <div className="date-container">
        <Grid container spacing={1} justifyContent="center" flexWrap="nowrap" alignItems="center" marginBottom="1rem">
          <Grid item >
            <h4>{i18n.FROM}</h4>
          </Grid>
          <Grid item >
            <MobileDatePicker
              // label="Desde"
              inputFormat="DD/MM/yyyy"
              value={valueFor}
              onChange={(newValue: Date | null) => {
                setValueFor(newValue);
              }}
              onAccept={() => {
                requestDate("start");
              }}
              renderInput={(params: any) => <TextField hiddenLabel {...params} variant="filled" size="small" />}
            />
          </Grid>
          <Grid item >
            <h4>{i18n.UNTIL}</h4>
          </Grid>
          <Grid item >
            <MobileDatePicker
              // label="Hasta"
              inputFormat="DD/MM/yyyy"
              value={valueTo}
              onChange={(newValue: Date | null) => {
                setValueTo(newValue);
              }}
              onAccept={() => {
                requestDate("end");
              }}
              renderInput={(params: any) => <TextField hiddenLabel {...params} variant="filled" size="small" />}
            />
          </Grid>
          <Grid item >
            <Button
              fullWidth
              variant="contained"
              className="btn-primary rounded-button"
              color="inherit"
              style={{ borderRadius: "20px", background: "#878786" }}
              onClick={() => {
                requestDate();
              }}
            >
              <strong>Ok</strong>
            </Button>
          </Grid>

        </Grid>
      </div>}

      {
        props?.typeTab === "1" &&
        <div className="input-container">
          <Paper
            component="form"
            className="form-input-custom"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <InputBase
              className="uppercase"
              onChange={(event) => {
                event.preventDefault();
                setPlate(event.target.value.toLocaleUpperCase())
              }}
              sx={{ ml: 1, flex: 1 }}
              placeholder={i18n.PLATE}
              inputProps={{ "aria-label": "Placa" }}
              value={plateCode}
            />
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton
              color="primary"
              sx={{ p: "10px" }}
              aria-label="Buscar código"
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                // searchTab("plate");
                requestDate();
              }}
            >
              <Loading loading={loadingPlate}>
                <IconSearch />
              </Loading>
            </IconButton>
          </Paper>
        </div>
      }

      {
        props?.typeTab === "2" &&
        <div className="input-container">
          <Paper
            component="form"
            className="form-input-custom"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <InputBase
              className="uppercase"
              onChange={(event) => {
                event.preventDefault();
                setDoc(event.target.value.toLocaleUpperCase())
              }}
              sx={{ ml: 1, flex: 1 }}
              placeholder={placeHolderSearch}
              inputProps={{ "aria-label": `${placeHolderSearch}` }}
              value={documentCode}
            // type="number"
            />
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton
              color="primary"
              sx={{ p: "10px" }}
              aria-label="Buscar código"
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                // searchTab("document");
                requestDate();
              }}
            >
              <Loading loading={loadingDoc}>
                <IconSearch />
              </Loading>
            </IconButton>
          </Paper>
        </div>
      }

      {store?.downloadReportActivationsExcel && (
        <Grid container spacing={1} justifyContent="end" flexWrap="nowrap" alignItems="center" textAlign="right" marginBottom="1rem">
          <Grid item xs={12} >
            <Button
              variant="contained"
              className="btn-primary rounded-button"
              color="primary"
              style={{ borderRadius: "20px" }}
              onClick={() => {
                handleDownloadReport();
              }}
            >
              <ContentPasteGoIcon />
              <strong> {i18n.EXPORT}</strong>
            </Button>
          </Grid>
        </Grid>
      )}

      <Loading loading={loading}>
        <TableContainer component={Paper} className="container-table-list" sx={{ maxHeight: 460 }}>
          <Table stickyHeader size="small" aria-label="warranty list">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontSize: "0.8rem !important" }}>
                  {i18n.WARRANTY_CARDS_ACTIVATED}
                </TableCell>
                {/* <TableCell sx={{ fontSize: "0.8rem !important" }} align="right">
                  #ID
                </TableCell>
                <TableCell
                  sx={{
                    pl: 1,
                    pr: 1,
                    textAlign: "center",
                    fontSize: "0.8rem !important",
                  }}
                  align="right"
                >
                  DET.
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {props?.typeTab === "0" && store?.warranty_activation_list
                .filter((f: any) => search(f))
                .sort((a: any, b: any) => {
                  if (a.DocEntry < b.DocEntry) return 1;
                  if (a.DocEntry > b.DocEntry) return -1;
                  return 0;
                })
                .map((row: any) => (
                  <WarrantyActivationListItem
                    key={row.Id ?? `AL${row.IdTarjeta}`}
                    {...row}
                    callback={open}
                    setDetailParent={setDetailParent}
                  />
                ))}

              {props?.typeTab === "1" && store?.warranty_activation_plate_list
                .filter((f: any) => search(f))
                .sort((a: any, b: any) => {
                  if (a.DocEntry < b.DocEntry) return 1;
                  if (a.DocEntry > b.DocEntry) return -1;
                  return 0;
                })
                .map((row: any) => (
                  <WarrantyActivationListItem
                    key={row.Id ?? `AP${row.IdTarjeta}`}
                    {...row}
                    callback={open}
                    setDetailParent={setDetailParent}
                  />
                ))}

              {props?.typeTab === "2" && store?.warranty_activation_document_list
                .filter((f: any) => search(f))
                .sort((a: any, b: any) => {
                  if (a.DocEntry < b.DocEntry) return 1;
                  if (a.DocEntry > b.DocEntry) return -1;
                  return 0;
                })
                .map((row: any) => (
                  <WarrantyActivationListItem
                    key={row.Id ?? `AD${row.IdTarjeta}`}
                    {...row}
                    callback={open}
                    setDetailParent={setDetailParent}
                  />
                ))}
            </TableBody>
          </Table>

        </TableContainer>
        {store?.userAppCorp && (
          <Stack spacing={2} mt={1} marginBottom={1}>
            <Pagination count={pageCount} page={currentPage} showFirstButton showLastButton onChange={handlePageChange} color="primary" />
          </Stack>
        )}
      </Loading>

      <BatteryListModal
        open={showBatteryList}
        handleClose={() => {
          setShowBatteryList(false);
        }}
        batteryList={batteryList}
        callback={setDetailParent}
        // type={store?.user?.Country == "PE" ? "NAC" : store?.user?.Country}
        type={store?.user?.Country}
      />

      {store.active_dialog === ACTIVE_DIALOG && (
        <Dialog
          closeCallback={open}
          title={<RenderTitle />}
          right={<RenderIcon />}
        >
          <SwitchView />
          <Bottom />
        </Dialog>
      )}
    </div>
  );
};
